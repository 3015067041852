.basic {
  &.modal {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  &.fade {
    opacity: 0;
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
    &.in {
      opacity: 1;
    }
  }
  &.modal.fade {
    &.in .modal-dialog {
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      -o-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    .modal-dialog {
      -webkit-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      -webkit-transform: translate(0, -25%);
      -ms-transform: translate(0, -25%);
      -o-transform: translate(0, -25%);
      transform: translate(0, -25%);
    }
  }
  .basic-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    color: #292b2c;
  }
  .basic-close-light-box {
    position: absolute;
    top: -31px;
    right: -20px;
    font-size: 30px;
    color: rgb(255, 255, 255);
    text-shadow: none;
  }
  h5 {
    margin-bottom: 0;
    color: #757575;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    margin-right: 10px;
  }
}
