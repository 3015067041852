import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LayoutComponent } from './layout/layout.component';
import { LoaderComponent } from './layout/loader/loader.component';
import { OrganizationClustersResolver } from './resolver/organization-clusters-resolver';
import { ApiDocComponent } from './theme/api-doc/api-doc.component';
import { AuthModule } from './theme/auth/auth.module';
import { DashboardComponent } from './theme/dashboard/dashboard.component';
import { DelegationComponent } from './theme/delegation/delegation.component';
import { IAParametersComponent } from './theme/ia-parameters/ia-parameters.component';
import { JournalComponent } from './theme/journal/journal.component';
import { JournalBisComponent } from './theme/journalbis/journalbis.component';
import { MaintenanceComponent } from './theme/maintenance/maintenance.component';
import { MetricsComponent } from './theme/metrics/metrics.component';
import { MyOrganizationComponent } from './theme/my-organizations/my-organization.component';
import { AddEditOrganizationComponent } from './theme/add-organization/add-edit-organization.component';
import { MyProfileComponent } from './theme/my-profile/my-profile.component';
import { MySitesComponent } from './theme/my-sites/my-sites.component';
import { PrintComponent } from './theme/print/print.component';
import { MyLogsComponent } from './theme/my-logs/my-logs.component';
import { FORGOT_SUFFIX, LOGIN_SUFFIX } from './theme/auth/auth-routing.module';
import { AdminModule } from './theme/admin/admin.module';
import { TitleResolver } from './resolver/title.resolver';
import { AddSiteComponent } from './theme/add-site/add-site.component';

export const EXTERNAL_COMPANY_WEBSITE_LINK = 'https://ogosecurity.com';
export const EXTERNAL_RFC_5424_SYSLOG_LINK = 'https://datatracker.ietf.org/doc/html/rfc5424#section-6.2.5';
export const EXTERNAL_RFC_7234_CACHE_LINK = 'https://datatracker.ietf.org/doc/html/rfc7234';

export const STATIC_LINK_OGO_CA_CERT = '/assets/push-log/ca-certs/ogo-logexport-ca.pem';
export const STATIC_LINK_OGO_LOG_EXAMPLE = '/assets/push-log/ogo-log-export-example-sentinel-v2.json';

export const AUTH_PREFIX = 'auth';
export const ADD_ROUTE = 'add';
export const AUTH_LOGIN_ROUTE = `${AUTH_PREFIX}/${LOGIN_SUFFIX}`;
export const AUTH_LOGIN_MFA_ROUTE = `${AUTH_PREFIX}/${LOGIN_SUFFIX}/mfa`;
export const AUTH_PWD_FORGOT_ROUTE = `${AUTH_PREFIX}/${FORGOT_SUFFIX}`;
export const DASHBOARD_ROUTE = 'dashboard';
export const MY_ACCOUNT_PREFIX = 'account';
export const MY_PROFILE_SUFFIX = 'profile';
export const MY_PROFILE_ROUTE = `${MY_ACCOUNT_PREFIX}/${MY_PROFILE_SUFFIX}`;
export const MY_ORGANIZATION_SUFFIX = 'organization';
export const MY_ORGANIZATION_ROUTE = `${MY_ACCOUNT_PREFIX}/${MY_ORGANIZATION_SUFFIX}`;
export const ADD_ORGANIZATION_ROUTE = 'add-organization';
export const JOURNAL_ROUTE = 'journal';
export const ADMIN_PREFIX = 'admin';
export const PRINT_ROUTE = 'print';
export const MY_LOGS_ROUTE = 'logs';
export const MY_SITES_ROUTE = 'sites';
export const API_DOC_ROUTE = 'api-doc';
export const ADMIN_CLUSTER_PREFIX = 'admin-cluster';
export const ADMIN_CLUSTER_SITES_SUFFIX = 'sites';
export const ADMIN_CLUSTER_SITES_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${ADMIN_CLUSTER_SITES_SUFFIX}`;
export const ADMIN_CLUSTER_DASHBOARD_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${DASHBOARD_ROUTE}`;
export const ADMIN_CLUSTER_LOGS_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${MY_LOGS_ROUTE}`;
export const ADMIN_CLUSTER_METRICS_SUFFIX = 'metrics';
export const ADMIN_CLUSTER_METRICS_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${ADMIN_CLUSTER_METRICS_SUFFIX}`;
export const ADMIN_CLUSTER_JOURNAL_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${JOURNAL_ROUTE}`;
export const ADMIN_CLUSTER_DELEGATION_SUFFIX = 'delegation';
export const ADMIN_CLUSTER_DELEGATION_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${ADMIN_CLUSTER_DELEGATION_SUFFIX}`;
export const ADMIN_CLUSTER_IA_PARAMETERS_SUFFIX = 'ia-parameters';
export const ADMIN_CLUSTER_IA_PARAMETERS_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${ADMIN_CLUSTER_IA_PARAMETERS_SUFFIX}`;
export const ADMIN_CLUSTER_MAINTENANCE_SUFFIX = 'maintenance';
export const ADMIN_CLUSTER_MAINTENANCE_ROUTE = `${ADMIN_CLUSTER_PREFIX}/${ADMIN_CLUSTER_MAINTENANCE_SUFFIX}`;

const routes: Routes = [
  {
    path: '',
    component: LoaderComponent,
    children: [
      {
        path: '',
        redirectTo: AUTH_LOGIN_ROUTE,
        pathMatch: 'full',
        canLoad: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Login' },
      },
      {
        path: AUTH_PREFIX,
        loadChildren: () => AuthModule,
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    canLoad: [AuthGuard],
    title: TitleResolver,
    children: [
      {
        path: 'admin',
        loadChildren: () => AdminModule,
      },
      {
        path: MY_PROFILE_ROUTE,
        component: MyProfileComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'MyProfile' },
      },
      {
        path: MY_ORGANIZATION_ROUTE,
        component: MyOrganizationComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'MyOrganization' },
      },
      {
        path: ADD_ORGANIZATION_ROUTE,
        component: AddEditOrganizationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: JOURNAL_ROUTE,
        component: JournalBisComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Journal' },
      },
      {
        path: DASHBOARD_ROUTE,
        component: DashboardComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'TableauDeBord' },
      },
      {
        path: PRINT_ROUTE,
        component: PrintComponent,
        canActivate: [AuthGuard],
      },
      {
        path: MY_LOGS_ROUTE,
        component: MyLogsComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Tracker' },
      },
      {
        path: MY_SITES_ROUTE,
        component: MySitesComponent,
        data: { title: 'MySites', adminClusterView: false },
        canActivate: [AuthGuard],
        title: TitleResolver,
      },
      {
        path: MY_SITES_ROUTE + '/add',
        component: AddSiteComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'MySites', adminClusterView: false },
      },
      {
        path: MY_SITES_ROUTE + '/:siteName',
        component: AddSiteComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'MySites', adminClusterView: false },
      },
      {
        path: API_DOC_ROUTE,
        component: ApiDocComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'API Doc' },
      },
      {
        path: ADMIN_CLUSTER_DASHBOARD_ROUTE,
        component: DashboardComponent,
        data: { title: 'TableauDeBord', adminClusterView: true },
        canActivate: [AuthGuard],
        title: TitleResolver,
      },
      {
        path: ADMIN_CLUSTER_LOGS_ROUTE,
        component: MyLogsComponent,
        data: { title: 'Logs', adminClusterView: true },
        canActivate: [AuthGuard],
        title: TitleResolver,
      },
      {
        path: ADMIN_CLUSTER_SITES_ROUTE,
        component: MySitesComponent,
        data: { title: 'Sites', adminClusterView: true },
        resolve: { clusters: OrganizationClustersResolver },
        canActivate: [AuthGuard],
        title: TitleResolver,
      },
      {
        path: ADMIN_CLUSTER_SITES_ROUTE + '/:siteName',
        component: AddSiteComponent,
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'MySites', adminClusterView: true },
      },
      {
        path: ADMIN_CLUSTER_METRICS_ROUTE,
        component: MetricsComponent,
        resolve: { clusters: OrganizationClustersResolver },
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Metrics' },
      },
      {
        path: ADMIN_CLUSTER_JOURNAL_ROUTE,
        component: JournalComponent,
        resolve: { clusters: OrganizationClustersResolver },
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Journal' },
      },
      {
        path: ADMIN_CLUSTER_DELEGATION_ROUTE,
        component: DelegationComponent,
        resolve: { clusters: OrganizationClustersResolver },
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Delegation' },
      },
      {
        path: ADMIN_CLUSTER_IA_PARAMETERS_ROUTE,
        component: IAParametersComponent,
        resolve: { clusters: OrganizationClustersResolver },
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'IAParameters' },
      },
      {
        path: ADMIN_CLUSTER_MAINTENANCE_ROUTE,
        component: MaintenanceComponent,
        resolve: { clusters: OrganizationClustersResolver },
        canActivate: [AuthGuard],
        title: TitleResolver,
        data: { title: 'Maintenance' },
      },
      { path: '**', redirectTo: DASHBOARD_ROUTE, pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
