.card {
  .card-header {
    .card-header-right {
      .card-option {
        width: 190px;
        transition: unset;
      }
    }
    & + .card-block-hid {
      .card-block,
      .card-block-big,
      .card-block-small {
        padding-top: 0;
      }
    }
  }
}
