#trafic-graph,
#attacks-donut {
  .theme-loader {
    position: absolute;
    margin: 0;
    background: none !important;
  }
}

.app-world-map {
  height: 700px !important;
}

.btn {
  text-transform: inherit;
}

.fi {
  background-size: cover !important;
  top: -1px !important;
  height: 16px !important;
  width: 1.733333em !important;
  margin-right: 5px;
}

.flex-ip-flag {
  display: flex;
  align-items: center;
}

.ng-input input {
  caret-color: transparent !important;
}

.to-right {
  padding-top: 15px;
  margin-bottom: 14px;
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
}

.graph-title-no-data {
  font-size: 14px;
  font-family: Arial;
  font-weight: bold;
}

.chart-interval {
  top: 34px;
}

.pcoded-item {
  background: #3b4650;
}

.new-site {
  margin: 20px 0;
  font-size: 20px;

  .new-site-link {
    font-size: 20px;
    border: 1px solid #4099ff;
    border-radius: 3px;
    padding: 8px 12px;
    color: white;
    background: #4099ff;
  }
}

.tabs-dashboard-tables {
  .btn {
    border-bottom: none;
  }

  ~ .card {
    .ngx-datatable.data-table {
      border-top-left-radius: 0;
    }
  }
}
