import { Env } from './env';

export const environment: Env = {
  URL: 'https://d1-stg.ogosecurity.com/api/ogo-backend/',
  featureFlags: { cdn: true },
  reloadOnError: false,
  silentConsole: false,
  detectVersionChange: true,
  enableGrooveHQ: true,
  version: 'ef3178',
};
