import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddSiteComponent } from './add-site.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExpertComponent } from './expert/expert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { CertificatesComponent } from './certificates/certificates.component';
import { GeoblockComponent } from './geoblock/geoblock.component';
import { AccessControlRulesComponent } from './access-control-rules/access-control-rules.component';
import { RewriteRulesComponent } from './rewrite-rules/rewrite-rules.component';
import { CacheComponent } from './cache/cache.component';
import { NgxSearchFilterModule } from 'ngx-search-filter';
import { TrackByKeyDirective } from '../../shared/track-by-key.directive';
import { RouterLink } from '@angular/router';
import { GeneralConfigComponent } from './general-config/general-config.component';
import { CacheCdnComponent } from './cache-cdn/cache-cdn.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    DragDropModule,
    NgSelectModule,
    NgxSearchFilterModule,
    TrackByKeyDirective,
    RouterLink,
  ],
  providers: [],
  exports: [AddSiteComponent],
  declarations: [
    AddSiteComponent,
    GeneralConfigComponent,
    CertificatesComponent,
    ExceptionsComponent,
    GeoblockComponent,
    AccessControlRulesComponent,
    RewriteRulesComponent,
    ExpertComponent,
    CacheComponent,
    CacheCdnComponent,
  ],
})
export class AddSiteModule {}
