import { Component, Input } from '@angular/core';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'app-help',
  standalone: false,

  template: `<label class="badge badge-sm bg-dark ml-1">
    <a style="color: inherit" target="_blank" [href]="url">?</a>
  </label>`,
  styleUrls: ['../../../assets/icon/icofont/css/icofont.scss'],
  styles: `
    label {
      margin-bottom: 0;
    }
  `,
})
export class HelpComponent {
  @Input({ required: true }) url: string;
}
