import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { areaOpacity, cacheStatusColors, cacheStatusOrder, RESPONSE_TIME_SUM } from '../graph/graph';
import { formatDuration, formatNumber, upperFirstLetter } from 'app/shared/utils/data-utils';
import { AuthService } from 'app/services/auth.service';
import {
  ActionEnum,
  Rps,
  SiteTrafficBreakdown,
  UrlBandwidth,
  UrlResponseCodesCount,
  UrlStats,
} from '../../../services/logs.service';
import * as Highcharts from 'highcharts/highcharts';
import { TooltipPositionerPointObject, XAxisOptions, YAxisOptions } from 'highcharts/highcharts';

type ChartOptions = Highcharts.Options & {
  xAxis: XAxisOptions;
  yAxis: YAxisOptions;
};

// There is a bug in HighCharts which applies bar.opacity twice, hence th square root
const barOpacity = Math.sqrt(areaOpacity);

const baseChart: ChartOptions = {
  accessibility: {
    enabled: false,
  },
  title: {
    style: {
      color: '#434343',
      fontSize: '15px',
    },
    y: -15,
  },
  chart: {
    animation: false,
    height: 400,
    type: 'bar',
    spacing: [40, 50, 20, 10],
  },
  xAxis: {
    labels: {
      style: {
        fontSize: '0.7em',
      },
    },
  },
  yAxis: {
    title: {
      text: null,
    },
  },
  tooltip: {
    outside: true,
    positioner: function (labelWidth, labelHeight, point: TooltipPositionerPointObject) {
      return {
        x: point.plotX + point.shapeArgs['width'] / 2 - labelWidth / 2,
        y: point.plotY - labelHeight - 5,
      };
    },
  },
  legend: {
    itemStyle: {
      fontSize: '0.7em',
      textOverflow: 'ellipsis',
    },
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    bar: {
      borderRadius: '10%',
      groupPadding: 0.1,
      pointPadding: 0,
    },
    series: {
      animation: false,
      stacking: null,
      events: { click: null },
    },
  },
  exporting: {
    buttons: {
      contextButton: {
        align: 'right',
        x: 30,
        y: -36,
        menuItems: [
          'printChart',
          'separator',
          'downloadPNG',
          'downloadJPEG',
          'downloadSVG',
          'separator',
          'downloadCSV',
          'downloadXLS',
        ],
      },
    },
  },
};
const baseCssXAxis =
  'text-decoration: underline; cursor: pointer; text-overflow: ellipsis; word-wrap: break-word; word-break; overflow: hidden; white-space: nowrap;';

function legendBelowBar() {
  return `<div align="center" title="${this.value}" style="${baseCssXAxis}; break-all; width: 500px; margin-left: 5px; margin-top: 10px;">${this.value}</div>`;
}

@Injectable()
export class Bar {
  constructor(
    private translate: TranslateService,
    private auth: AuthService,
  ) {}

  getBaseChart() {
    return JSON.parse(JSON.stringify(baseChart));
  }

  formatDataTrafic(data: (SiteTrafficBreakdown & Rps)[], series: 'total' | ActionEnum.BLOCKED): ChartOptions {
    const rpsData = data.map((d) => d.rps);
    let { xAxis, yAxis, tooltip, title, plotOptions, ...restBaseChart } = this.getBaseChart();
    xAxis.categories = data.map((d) => d.site);
    xAxis.labels.formatter = function () {
      return `<div title="${this.value}" style="${baseCssXAxis}">${this.value}</div>`;
    };

    title.text = this.translate.instant('TotalPerSite');
    yAxis = [
      {
        labels: {
          style: {
            fontSize: '0.7em',
          },
        },
        title: {
          text: this.translate.instant('requests'),
        },
      },
      {
        opposite: true,
        labels: {
          style: {
            color: '#FF5370',
            fontSize: '0.7em',
          },
        },
        title: {
          text: this.translate.instant('BlockedSuspicious'),
        },
      },
    ];
    tooltip.formatter = function () {
      const rpsValue = rpsData[this.point.index];

      if (this.series?.userOptions?.custom?.slug === 'total') {
        return `${this.series.name} ${this.x} : <b>${this.y}</b>, RPS : <b>${rpsValue}</b>`;
      }

      return `${this.series.name} ${this.x} : <b>${this.y}</b>`;
    };

    return {
      ...restBaseChart,
      xAxis,
      yAxis,
      tooltip,
      title,
      plotOptions,
      series: [
        {
          type: 'bar',
          name: this.translate.instant('requests'),
          data: data.map((d) => d.total),
          color: '#c3c3c3',
          opacity: barOpacity,
          yAxis: 0,
          legendIndex: series == 'total' ? 0 : 2,
        },
        {
          type: 'bar',
          name: this.translate.instant('suspiciouses'),
          custom: {
            slug: ActionEnum.SUSPICIOUS,
          },
          data: data.map((d) => d.suspect),
          color: '#f7a35c',
          yAxis: 1,
          legendIndex: 1,
        },
        {
          type: 'bar',
          name: this.translate.instant('Blocked'),
          custom: {
            slug: ActionEnum.BLOCKED,
          },
          data: data.map((d) => d.blocked),
          color: '#ff5370',
          yAxis: 1,
          legendIndex: series == 'total' ? 2 : 0,
        },
        {
          type: 'bar',
          name: this.translate.instant('RPS'),
          custom: {
            slug: 'rps',
          },
          data: rpsData,
          color: '#ff5370',
          visible: false,
          showInLegend: false,
        },
      ],
    };
  }

  private getUrlBaseChart(data: { url: string }[]): ChartOptions {
    const chart = this.getBaseChart();
    chart.xAxis.categories = data.map((d) => d.url);
    chart.xAxis.labels = {
      align: 'left',
      x: 0,
      y: -24,
      useHTML: true,
      formatter: legendBelowBar,
    };
    chart.plotOptions.bar.pointWidth = 20;
    return chart;
  }

  formatDataResponseTime(data: UrlStats[]): Highcharts.Options {
    const lang = this.auth.getCurrentLanguage();

    const baseChart = this.getUrlBaseChart(data);
    let { xAxis, tooltip, title, plotOptions, yAxis, chart, ...restBaseChart } = baseChart;
    chart.type = 'columnrange';
    chart.inverted = true;
    (baseChart.yAxis as YAxisOptions).labels = {
      formatter: function () {
        return formatDurationAxis(this.value as number, lang);
      },
    };
    yAxis.title = {
      text: this.translate.instant('ResponseTime') + ' (ms)',
    };
    plotOptions.columnrange = {
      borderRadius: '50%',
      pointWidth: 15,
    };
    title.text = this.translate.instant('SlowestURLs');
    tooltip.formatter = function () {
      if (
        typeof this.point.low !== 'undefined' &&
        this.point.low !== null &&
        typeof this.point.high !== 'undefined' &&
        this.point.high !== null
      ) {
        return `<b>Min</b> : <b>${formatDuration(this.point.low, lang)}</b>, <b>Max</b> : <b>${formatDuration(this.point.high, lang)}</b>`;
      } else {
        return `<b>${this.series.name}</b> : <b>${formatDuration(this.y, lang)}</b>`;
      }
    };

    return {
      ...restBaseChart,
      chart,
      xAxis,
      yAxis,
      tooltip,
      title,
      plotOptions,
      series: [
        {
          type: 'columnrange',
          name: this.translate.instant('MinMaxInterval'),
          custom: {
            slug: 'minMax',
          },
          data: data.map((d) => [Math.round(d.min), Math.round(d.max)]),
          color: '#088cff',
        },
        {
          type: 'scatter',
          color: '#4099ff',
          marker: {
            symbol: 'circle',
            lineColor: '#FFFFFF',
            lineWidth: 1,
          },
          data: data.map((d) => d.avg),
          name: this.translate.instant('Avg'),
        },
      ],
    };
  }

  formatDataResponsesCodes(data: UrlResponseCodesCount[]): Highcharts.Options {
    let { xAxis, yAxis, title, plotOptions, ...restBaseChart } = this.getUrlBaseChart(data);
    plotOptions.series.stacking = 'normal';
    title.text = this.translate.instant('TopErrorURLs');

    return {
      ...restBaseChart,
      xAxis,
      yAxis,
      plotOptions,
      title,
      series: [
        {
          type: 'bar',
          name: this.translate.instant('5XX'),
          custom: {
            slug: '5XX',
          },
          data: data.map((d) => d.count5xx),
          color: '#ff5370',
          index: 1,
          legendIndex: 1,
        },
        {
          type: 'bar',
          name: this.translate.instant('4XX'),
          custom: {
            slug: '4XX',
          },
          data: data.map((d) => d.count4xx),
          color: '#f7a35c',
          index: 2,
          legendIndex: 0,
        },
      ],
    };
  }

  formatDataResponseTimeSum(data: UrlStats[]): Highcharts.Options {
    const lang = this.auth.getCurrentLanguage();

    let { xAxis, yAxis, tooltip, title, plotOptions, ...restBaseChart } = this.getUrlBaseChart(data);
    yAxis.labels = {
      formatter: function () {
        return formatDurationAxis(this.value as number, lang);
      },
    };
    yAxis.title = {
      text: this.translate.instant('CumulativeResponseTime') + ' (ms)',
    };
    plotOptions.series.stacking = 'normal';
    title.text = this.translate.instant('TopCpuConsumingURLs');
    tooltip.formatter = function () {
      return `<b>${this.series.name}</b> : <b>${formatDuration(this.y, lang)}</b>`;
    };

    return {
      ...restBaseChart,
      xAxis,
      yAxis,
      plotOptions,
      tooltip,
      title,
      series: [
        {
          type: 'bar',
          name: `${this.translate.instant('Graph.seriesName.responseTimeSum')}`,
          custom: {
            slug: RESPONSE_TIME_SUM,
          },
          data: data.map((d) => d.sum),
          color: 'green',
        },
      ],
    };
  }

  formatDataBandwidth(data: UrlBandwidth[]): Highcharts.Options {
    let { xAxis, yAxis, tooltip, title, plotOptions, ...restBaseChart } = this.getUrlBaseChart(data);
    const bytes = this.translate.instant('bytes');
    yAxis.title = { text: upperFirstLetter(bytes) };
    plotOptions.series.stacking = 'normal';
    plotOptions.bar.opacity = barOpacity;
    title.text = this.translate.instant('TopBandwidthURLs');
    const lang = this.auth.getCurrentLanguage();
    tooltip.formatter = function () {
      return `<b>${this.series.name}</b> : <b>${formatNumber(this.y, lang)}</b> ${bytes}`;
    };

    return {
      ...restBaseChart,
      xAxis,
      yAxis,
      plotOptions,
      tooltip,
      title,
      series: cacheStatusOrder.map((cacheStatus) => ({
        type: 'bar',
        name: `${this.translate.instant(`CacheStatus.${cacheStatus}`)}`,
        custom: { slug: cacheStatus },
        data: data.map((d) => d.bandwidth[cacheStatus]),
        color: cacheStatusColors[cacheStatus],
      })),
    };
  }
}

export function formatDurationAxis(ms: number, lang: string): string {
  if (!ms || isNaN(ms)) {
    return '';
  }

  if (ms < 1) {
    return ms * 1000 + 'µs';
  } else if (ms < 1000) {
    return Math.round(ms) + 'ms';
  } else {
    return showSeconds(ms / 1000, lang);
  }
}

function showSeconds(value: number, lang: string): string {
  return new Intl.NumberFormat(lang, { maximumFractionDigits: 2 }).format(value) + 's';
}
