@media print {
  app-slow-requests-table .ngx-datatable {
    .datatable-body-cell.site, .datatable-header-cell.site {
      width: 30% !important;
    }

    .datatable-body-cell.path, .datatable-header-cell.path {
      width: 60% !important;
    }

    .datatable-body-cell.response-time, .datatable-header-cell.response-time {
      width: 10% !important;
    }
  }
}

