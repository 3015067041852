import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode, DatatableComponent } from '@siemens/ngx-datatable';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { AuthService } from 'app/services/auth.service';
import { HostModeService } from 'app/shared/hostMode/hostMode.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { distinctUntilChanged, Observable, startWith, Subject, switchMap } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import _ from 'lodash';
import { AdminOrganizationService } from 'app/services/admin-organization.service';
import { AdminSiteService, Site } from '../../../services/admin-site.service';
import { SpringPage } from '../../../shared/page';
import { pick } from '../../../shared/utils/data-utils';

interface SiteFilters {
  clusterId?: number;
  status?: string;
  mode?: string;
  filter?: string;
}

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class SitesComponent implements OnInit, AfterViewInit {
  lang: string;

  sites: Site[];
  private filters$ = new Subject<SiteFilters>();
  filters: SiteFilters = {};
  clusters = [];
  statutHotes = [];
  curPage: number = 1;
  pageSize = 10;
  totalElements = null;
  organizations = [];

  @ViewChild('searchInput') searchInput: ElementRef;

  @ViewChild('myTable') set datatable(table: DatatableComponent) {
    table.columnMode = ColumnMode.force; // make resizing table header working
  }
  constructor(
    private http: HttpClient,
    public translate: TranslateService,
    private toastr: ToastrService,
    public hostModeService: HostModeService,
    private adminSiteService: AdminSiteService,
    private adminOrganizationService: AdminOrganizationService,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.getClusters();
    this.getStatutHote();
    this.lang = this.auth.getCurrentLanguage();
    this.filters$
      .pipe(
        startWith({}),
        debounceTime(300),
        distinctUntilChanged(_.isEqual),
        switchMap((filters) => this.searchSites(filters)),
      )
      .subscribe({
        next: (page: any) => {
          this.sites = page.content;
          this.totalElements = page.totalElements;
        },
        error: () => {
          this.sites = [];
          this.totalElements = null;
        },
      });
    this.changeSearch();
  }

  ngAfterViewInit(): void {}

  confirmCron12H() {
    return Swal.fire({
      html: this.translate.instant('VoulezVousExecuterCron'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.cron12H();
      }
    });
  }

  private cron12H() {
    this.http.post('jobs/cron12H?async=true', null).subscribe({
      next: (res: any) => {
        this.toastr.success(this.translate.instant('CRONExecuteeSucces'));
        this.getSites();
      },
      error: () => {
        this.toastr.error(this.translate.instant('CRONExecuteeEchoue'));
      },
    });
  }

  confirmProvCluster() {
    return Swal.fire({
      html: this.translate.instant('ConfirmProvCluster'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.provCluster();
      }
    });
  }

  private provCluster() {
    this.http
      .post('hote/provisionByCluster', {
        data: { clusterId: this.filters.clusterId },
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('CRONExecuteeSucces'));
          this.getSites();
        } else {
          this.toastr.error(this.translate.instant('CRONExecuteeEchoue'));
        }
      });
  }

  changePage(evt) {
    this.curPage = evt.page;
    this.getSites();
  }

  changeSearch() {
    this.curPage = 1;
    this.filters$.next({ ...this.filters });
  }

  getSites() {
    this.searchSites(this.filters).subscribe((res: any) => {
      this.sites = res?.content || [];
      this.totalElements = res?.totalElements;
      this.curPage = res?.number + 1;
    });
  }

  searchSites(filters: SiteFilters): Observable<SpringPage<Site>> {
    return this.adminSiteService.getSites$(filters);
  }

  getClusters() {
    this.http
      .post('cluster/get', {
        data: {},
      })
      .subscribe((res: any) => {
        this.clusters = res.items;
      });
  }

  getStatutHote() {
    this.http
      .post('statutHote/get', {
        data: {},
      })
      .subscribe((res: any) => {
        this.statutHotes = res.items.map((p: any) => {
          p.libelle = this.lang === 'en' ? p.libelleEn : p.libelle;
          return p;
        });
      });
  }

  refreshStatus(site: Site) {
    return Swal.fire({
      html: this.translate.instant('VoulezVousLancerCorrectionSite'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.http
          .post('hote/refreshStatus', {
            data: {
              id: site.id,
            },
          })
          .subscribe((res: any) => {
            if (!res.hasError) {
              this.toastr.success(this.translate.instant('CorrectionSucces'));
            }
          });
      }
    });
  }

  forceProvSite(site: Site) {
    return Swal.fire({
      html: this.translate.instant('VoulezVousReprovisionnerSite'),
      showCancelButton: true,
      confirmButtonColor: '#4099ff',
      cancelButtonColor: '#d33',
      cancelButtonText: this.translate.instant('Annuler'),
      confirmButtonText: this.translate.instant('YesConfirm'),
      title: this.translate.instant('Confirmation'),
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
        this.http
          .post('hote/provision', {
            data: {
              id: site.id,
            },
          })
          .subscribe((res: any) => {
            if (!res.hasError) {
              this.toastr.success(this.translate.instant('SiteReprovisionneSucces'));
            }
          });
      }
    });
  }

  setMode(id, oldValue, event) {
    this.http
      .post('hote/update', {
        datas: [{ id, mode: event.target.value }],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('OperationSuccess'));
        } else {
          event.target.value = oldValue;
          if (res.status.code == '938') {
            this.toastr.error(res.status.message);
          } else {
            this.toastr.error(this.translate.instant('OperationFailed'));
          }
        }
      });
  }

  async updateOrganizationOwner(siteName, organizationId) {
    try {
      await this.adminOrganizationService.changeSiteOrganizationOwner(siteName, organizationId);
      this.toastr.success(this.translate.instant('OperationSuccess'));
      this.getSites();
    } catch (error) {
      return this.toastr.error(`${this.translate.instant('Erreur')} : ${error}`);
    }
  }

  async loadOrganizations(searchTerm) {
    if (searchTerm.length >= 2) {
      let res: any = null;
      try {
        res = await this.adminOrganizationService.getOrganizations({ searchTerm });
      } catch (error) {
        return this.toastr.error(error.error);
      }

      res.content = res.content.map((o) => {
        return { companyNameAndCode: o.companyName + ' - ' + o.code, ...o };
      });
      this.organizations = res.content;
    }
  }

  updateManaged(site: Site) {
    this.http
      .post('hote/update', {
        datas: pick(site, ['id', 'managed']),
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.toastr.success(this.translate.instant('OperationSuccess'));
          this.getSites();
        } else {
          this.toastr.error(this.translate.instant('OperationFailed'));
        }
      });
  }
}
