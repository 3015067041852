import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { HostModeService, SiteMode } from 'app/shared/hostMode/hostMode.service';

import { ToastrService } from 'app/shared/toastr/toastr.service';
import { SitesService } from 'app/services/sites.service';
import { AuthService } from '../../../services/auth.service';
import { AdminOrganizationService } from 'app/services/admin-organization.service';

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
  standalone: false,
})
export class ClusterComponent implements OnInit {
  lang: string;

  user: any = {};
  cluster: EditableCluster = {};
  template: any = {};
  clusters: Cluster[] = [];
  clustersTwin: any = [];
  clusterTypes: any = [];
  elasticsearchs: any = [];
  organizations: any = [];
  SHOW_VIEW = 'LIST_CLUSTER';

  constructor(
    private http: HttpClient,
    private sites: SitesService,
    private translate: TranslateService,
    private auth: AuthService,
    private toastr: ToastrService,
    public hostModeService: HostModeService,
    private adminOrganizationService: AdminOrganizationService,
  ) {}

  ngOnInit() {
    this.user = this.auth.getUser();
    this.loadClusterTypes();
    this.loadClusters();
    this.loadElasticsearchs();
  }

  async loadOrganizations(searchTerm: string) {
    if (searchTerm.length >= 2) {
      let res: any = null;
      try {
        res = await this.adminOrganizationService.getOrganizations({ searchTerm });
      } catch (error) {
        return this.toastr.error(error.error);
      }

      res.content = res.content.map((o) => {
        return { companyNameAndCode: o.companyName + ' - ' + o.code, ...o };
      });
      this.organizations = res.content;
    }
  }

  editCluster(cluster: Cluster) {
    this.SHOW_VIEW = 'EDIT_CLUSTER';
    this.clustersTwin = this.clusters.filter((c) => c.id != cluster.id);
    if (cluster) {
      this.cluster = _.clone(cluster);
      this.organizations = [
        { companyNameAndCode: cluster.owner.companyName + '-' + cluster.owner.code, id: cluster.owner.id },
      ];
    } else {
      this.cluster = {};
    }
  }

  loadClusters() {
    this.http
      .post('cluster/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.clusters = res.items;
        }
      });
  }

  loadElasticsearchs() {
    this.http
      .post('elasticsearch/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.elasticsearchs = res.items;
        }
      });
  }

  loadClusterTypes() {
    this.http
      .post('clusterType/get', {
        data: {},
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.clusterTypes = res.items;
        }
      });
  }

  onCreateCluster(cluster: EditableCluster) {
    if (cluster.name === undefined || cluster.name.trim() === '') {
      this.toastr.error('Saisir le libellé');
      return;
    }
    if (cluster.ip === undefined || cluster.ip.trim() === '') {
      this.toastr.error("Saisir l'adresse IP");
      return;
    }
    if (cluster.endpoint === undefined || cluster.endpoint.trim() === '') {
      this.toastr.error("Saisir l'endpoint");
      return;
    }
    if (cluster.defaultSiteMode === undefined || cluster.defaultSiteMode.trim() === '') {
      this.toastr.error('Saisir le mode');
      return;
    }
    this.http
      .post('cluster/create', {
        datas: [
          {
            nom: cluster.name,
            ip: cluster.ip,
            ipv6: cluster.ipv6,
            clusterId: cluster.clusterId,
            cdnId: cluster.cdnId,
            ownerId: cluster.ownerId,
            endpoint: cluster.endpoint,
            clusterTwinId: cluster.clusterTwinId,
            elasticsearchId: cluster.elasticsearchId,
            clusterTypeId: cluster.clusterTypeId,
            isPublic: cluster.isPublic,
            defaultSiteMode: cluster.defaultSiteMode,
            supportsIpv6Origins: cluster.supportsIpv6Origins,
            supportsCache: cluster.supportsCache,
            trustedIps: cluster.trustedIps,
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.loadClusters();
          this.sites.purgeContext();
          this.SHOW_VIEW = 'LIST_CLUSTER';
          this.toastr.success(this.translate.instant('EnregistrementReussi'));
          this.cluster = {};
        } else if (res.status && res.status.message) {
          this.toastr.error(res.status.message);
        }
      });
  }

  onUpdateCluster(cluster: EditableCluster) {
    this.http
      .post('cluster/update', {
        datas: [
          {
            id: cluster.id,
            nom: cluster.name,
            ip: cluster.ip,
            ipv6: cluster.ipv6,
            clusterId: cluster.clusterId,
            cdnId: cluster.cdnId,
            ownerId: cluster.ownerId,
            endpoint: cluster.endpoint,
            clusterTwinId: cluster.clusterTwinId,
            elasticsearchId: cluster.elasticsearchId,
            clusterTypeId: cluster.clusterTypeId,
            isPublic: cluster.isPublic,
            managed: cluster.managed,
            defaultSiteMode: cluster.defaultSiteMode,
            supportsIpv6Origins: cluster.supportsIpv6Origins,
            supportsCache: cluster.supportsCache,
            trustedIps: cluster.trustedIps,
          },
        ],
      })
      .subscribe((res: any) => {
        if (!res.hasError) {
          this.loadClusters();
          this.SHOW_VIEW = 'LIST_CLUSTER';
          this.toastr.success(this.translate.instant('MiseAJourReussie'));
          this.cluster = {};
        } else {
          this.toastr.error(res.status.message);
        }
      });
  }
}

interface Cluster {
  id: number;
  name: string;
  clusterId: string;
  cdnId: string;
  owner: {
    id: number;
    code: string;
    companyName: string;
  };
  endpoint: string;
  elasticsearch: {
    id: number;
    name: string;
  };
  type: {
    id: number;
    name: string;
  };
  ip: string;
  ipv6?: string;
  isPublic: boolean;
  managed: boolean;
  supportsIpv6Origins: boolean;
  supportsCache: boolean;
  defaultSiteMode: SiteMode;
  trustedIps: string[];
}

type EditableCluster = Partial<
  Cluster & {
    ownerId: number;
    clusterTwinId: number;
    elasticsearchId: number;
    clusterTypeId: number;
  }
>;
