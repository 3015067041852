import { Global } from '../../global';
import { FeatureFlagKeys } from '../../../environments/feature-flags';
import { Directive, inject, Input, signal, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'app/services/auth.service';

@Directive({
  selector: '[featureFlag]',
  standalone: false,
})
export class FeatureFlagDirective {
  constructor(private auth: AuthService) {}
  templateRef = inject(TemplateRef);
  viewContainer = inject(ViewContainerRef);
  hasView = signal(false);

  @Input() set featureFlag(feature: FeatureFlagKeys) {
    if (this.featureEnabled(feature) && !this.hasView()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView.set(true);
    } else {
      this.viewContainer.clear();
      this.hasView.set(false);
    }
  }

  @Input() set featureFlagElse(elseTemplateRef: TemplateRef<any>) {
    if (!this.hasView()) {
      this.viewContainer.createEmbeddedView(elseTemplateRef);
    }
  }

  private featureEnabled(feature: FeatureFlagKeys): boolean {
    return Global.featureFlags[feature] || this.auth.userIsAdmin();
  }
}
