<div class="btn-group mt-3 tabs-over-graph">
  <button
    (click)="setGraphType(TRAFIC)"
    [disabled]="isLoading"
    class="btn btn-md waves-effect waves-light"
    [ngClass]="{ 'btn-inverse': graphType == TRAFIC }"
    type="button"
    [disabled]="!graphsRendered.overTime"
    [innerHTML]="'Traffic' | translate"
  ></button>
  <button
    (click)="setGraphType(RESPONSE_TIME)"
    [disabled]="isLoading"
    class="btn btn-md waves-effect waves-light"
    [ngClass]="{ 'btn-inverse': graphType == RESPONSE_TIME }"
    type="button"
    [disabled]="!graphsRendered.overTime"
    [innerHTML]="'ResponseTime' | translate"
  ></button>
  <button
    (click)="setGraphType(RESPONSES_CODES)"
    [disabled]="isLoading"
    class="btn btn-md waves-effect waves-light"
    [ngClass]="{ 'btn-inverse': graphType == RESPONSES_CODES }"
    type="button"
    [disabled]="!graphsRendered.overTime"
    [innerHTML]="'StatusCodes' | translate"
  ></button>
  <button
    (click)="setGraphType(BANDWIDTH)"
    [disabled]="isLoading"
    class="btn btn-md waves-effect waves-light"
    [ngClass]="{ 'btn-inverse': graphType == BANDWIDTH }"
    type="button"
    [disabled]="!graphsRendered.overTime"
    [innerHTML]="'BandwidthReport.title' | translate"
  ></button>
</div>
<div class="card with-tabs p-0 m-0">
  <div class="container-log-panels">
    <div #graphOverTimePanel class="log-panel left-log-panel m-0 position-relative">
      <div class="flex-align-items position-relative">
        <div class="btn-group ml-auto">
          <button
            class="btn btn-sm btn-square waves-effect waves-light"
            (click)="zoomOut()"
            [title]="'ZoomOut' | translate"
            [disabled]="
              !graphsRendered.overTime ||
              (disableGoForward(sites[ctx].current.period.range.end) &&
                disableGoBack(sites[ctx].current.period.range.start))
            "
            style="border-top-left-radius: 0"
          >
            {{ 'ZoomOut' | translate }} <i class="icofont icofont-ui-zoom-out ml-1"></i>
          </button>
          <button
            class="btn btn-sm btn-square waves-effect waves-light"
            (click)="strafe('left')"
            [disabled]="!graphsRendered.overTime || disableGoBack(sites[ctx].current.period.range.start)"
            [title]="'StrafeLeft' | translate"
          >
            <
          </button>
          <button
            style="border-right: none"
            class="btn btn-sm btn-square waves-effect waves-light"
            (click)="strafe('right')"
            [disabled]="!graphsRendered.overTime || disableGoForward(sites[ctx].current.period.range.end)"
            [title]="'StrafeRight' | translate"
          >
            >
          </button>
        </div>
      </div>
      <span class="chart-interval">{{ chartInterval ? 'Interval : ' + (chartInterval | durationSeconds) : '' }}</span>
      <app-graph
        [data]="graphOverTimeData[graphType]"
        (zoneSelected)="onPeriodEmittedFromZoneSelected($event)"
        (columnClicked)="onColumnClick($event)"
        (isRendered)="graphsRendered.overTime = true"
      >
      </app-graph>
      <span class="chart-total">{{
        total != null
          ? ('requests' | translate) + ' : ' + (total | number: '' : (lang == 'fr' ? 'fr-FR' : 'en-US'))
          : ''
      }}</span>
      <div [hidden]="graphsRendered.overTime" class="theme-loader flex">
        <div class="loader-track">
          <div class="loader-bar"></div>
        </div>
      </div>
    </div>
    <div #graphRepartitionsPanel class="log-panel right-log-panel m-0">
      <div class="p-t-2 flex-align-items">
        <div #graphRepartitionsContainer class="breakdown-container flex-align-items">
          <div class="arrow-container" [title]="'Minimize' | translate" (click)="toggleRepartitionsPanel()">
            <i class="icofont icofont-arrow-right flex-align-items"></i>
          </div>
          <div #graphRepartitions class="breakdown-graph position-relative">
            <div *ngIf="graphType == TRAFIC" class="btn-group mb-3">
              <button
                (click)="setRepartitionType(TRAFIC)"
                class="btn btn-sm btn-square waves-effect waves-light"
                style="border-top-left-radius: 0"
                [ngClass]="{ 'btn-inverse': repartitionsType == TRAFIC }"
                type="button"
              >
                <i class="icofont icofont-long-arrow-down"></i>
                {{ 'ByRequests' | translate }}
              </button>
              <button
                (click)="setRepartitionType(TRAFIC_SORT_BY_BLOCKED)"
                class="btn btn-sm btn-square waves-effect waves-light"
                [ngClass]="{ 'btn-inverse': repartitionsType == TRAFIC_SORT_BY_BLOCKED }"
                type="button"
              >
                <i class="icofont icofont-long-arrow-down"></i>
                {{ 'ByBlocked' | translate }}
              </button>
            </div>
            <div *ngIf="graphType == RESPONSE_TIME" class="btn-group mb-3">
              <div *ngFor="let type of [RESPONSE_TIME, RESPONSE_TIME_SUM]">
                <button
                  (click)="setRepartitionType(type)"
                  class="btn btn-sm btn-square waves-effect waves-light"
                  style="border-top-left-radius: 0"
                  [ngClass]="{ 'btn-inverse': repartitionsType == type }"
                  type="button"
                >
                  <i class="icofont icofont-long-arrow-down"></i>
                  {{ 'Graph.breakdown.' + type | translate }}
                </button>
              </div>
            </div>
            <app-bar-horizontal
              class="bar-horizontal-{{ repartitionsType }}"
              [data]="graphRepartitionsData[repartitionsType]"
              (barClicked)="repartitionsBarClicked($event)"
              (categoryClicked)="repartitionsCategoryClicked($event)"
              (isRendered)="graphsRendered.breakdown = true"
            ></app-bar-horizontal>
            <div [hidden]="graphsRendered.breakdown" class="theme-loader theme-loader-breakdown flex">
              <div class="loader-track">
                <div class="loader-bar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
