import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';

import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'app/shared/toastr/toastr.service';
import { MINUTE, SECOND } from '../shared/utils/data-utils';
import { Router } from '@angular/router';

const AUTO_LOGOUT_TIMEOUT = 20 * MINUTE; // in mins
const CHECK_INTERVAL = 15 * SECOND; // in ms
const STORE_KEY = 'lastAction';
@Injectable()
export class AutoLogoutService {
  interval: any;
  public getLastAction() {
    return parseInt(sessionStorage.getItem(STORE_KEY), 10);
  }
  public setLastAction(lastAction: number) {
    sessionStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(
    private toastr: ToastrService,
    private auth: AuthService,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.check();
    this.initListener();
    this.initInterval();
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    this.interval = setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  clearInterval() {
    clearInterval(this.interval);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + AUTO_LOGOUT_TIMEOUT;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {
      this.toastr.warning(this.translate.instant('ExpiredSession'));
      const state = this.router.routerState;
      const snapshot = state.snapshot;
      this.auth.redirectUrl = snapshot.url;
      this.auth.logout();
    }
  }
}
