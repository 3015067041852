import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getPageParams, PageRequest, SpringPage } from 'app/shared/page';
import { addHttpParams } from '../shared/utils/request-utils';
import { SiteMode } from '../shared/hostMode/hostMode.service';
import { Hsts, Organization } from './entities/site';

@Injectable({
  providedIn: 'root',
})
export class AdminSiteService {
  constructor(private http: HttpClient) {}

  getSites$(siteQuery: SiteQuery = null, page: PageRequest = null): Observable<SpringPage<Site>> {
    let params = addHttpParams(siteQuery, getPageParams(page));

    return this.http.get<SpringPage<Site>>(`admin/sites`, { params });
  }
}

export type Site = {
  id: number;
  name: string;
  destHost: string;
  destHostScheme: string;
  port: number;

  forceHttps: boolean;
  trustSelfSigned: boolean;
  noCopyXForwardedFor: boolean;
  panicMode: boolean;
  dryRun: boolean;
  hsts: Hsts;
  logExport: boolean;
  cacheEnabled: boolean;

  managed: boolean;
  mode: SiteMode;
  owner: Organization;
  cluster: Cluster;
  status: string;
  createdAt: string;
};

type Cluster = {
  id: number;
  clusterId: string;
  name: string;
};

interface SiteQuery {
  clusterId?: number;
  status?: string;
  mode?: string;
  filter?: string;
  contractIsNull?: boolean;
}
