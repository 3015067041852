@media print {
  app-blocked-ips-table .ngx-datatable {
    .datatable-header-cell.suspect {
      word-break: break-all;
    }

    .datatable-body-cell.ip, .datatable-header-cell.ip {
      width: 25% !important;
    }

    .datatable-body-cell.site, .datatable-header-cell.site {
      width: 29% !important;
    }

    .datatable-body-cell.number, .datatable-header-cell.number {
      width: 9% !important;
    }

    .datatable-body-cell.total, .datatable-header-cell.total {
      width: 10% !important;
    }
  }
}

.country-code {
  display: inline-block;
  width: 2em;
}
