@media print {
  app-most-frequent-requests-table .ngx-datatable {
    .datatable-body-cell.site,
    .datatable-header-cell.site {
      width: 30% !important;
    }

    .datatable-body-cell.path,
    .datatable-header-cell.path {
      width: 40% !important;
    }

    .datatable-body-cell.number,
    .datatable-header-cell.number {
      width: 10% !important;
    }

    .datatable-body-cell.duration,
    .datatable-header-cell.duration {
      width: 10% !important;
    }
  }
}
